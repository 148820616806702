import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12')
];

export const server_loads = [2,3];

export const dictionary = {
		"/": [5],
		"/(duka.bio.shop)/[shop_slug]/(home)": [6,[2,3]],
		"/(duka.bio.shop)/[shop_slug]/(shop)/cart": [7,[2,4]],
		"/(duka.bio.shop)/[shop_slug]/(shop)/checkout": [8,[2,4]],
		"/(duka.bio.shop)/[shop_slug]/(shop)/checkout/3rdparty/[checkout_status]": [~9,[2,4]],
		"/(duka.bio.shop)/[shop_slug]/(shop)/orderstatus/[orderId]": [~10,[2,4]],
		"/(duka.bio.shop)/[shop_slug]/(shop)/pay": [11,[2,4]],
		"/(duka.bio.shop)/[shop_slug]/(shop)/product/[product_slug]": [~12,[2,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';